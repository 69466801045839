import { navigate as reachNavigate } from '@reach/router';
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  Severity,
} from '@sentry/browser';
import { graphql } from 'gatsby';
import React from 'react';

import { Layout, LayoutBrandData } from '../components/layout';
import { Spinner } from '../components/spinner';
import * as analytics from '../services/analytics';
import { urlWithUtmInfo } from '../services/analytics';
import { authenticate } from '../services/kantan-client';

type AuthState = {
  isAuthenticating: boolean;
  error: string | null;
};

type AuthAction =
  | {
      type: 'success';
    }
  | {
      type: 'error';
      error: string | null;
    };

const authStateReducer: React.Reducer<AuthState, AuthAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case 'success':
      return { isAuthenticating: false, error: null };
    case 'error':
      return {
        isAuthenticating: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong';

const useAuthenticateAndRedirect = (
  token: string | null,
  redirectTo: string | null,
) => {
  const [state, dispatch] = React.useReducer(authStateReducer, {
    isAuthenticating: true,
    error: null,
  });

  React.useEffect(() => {
    addBreadcrumb({
      category: 'login',
      message: 'Logging in',
    });

    if (token === null) {
      captureMessage('No token provided for login', Severity.Error);
      dispatch({ type: 'error', error: SOMETHING_WENT_WRONG_MESSAGE });
    } else if (redirectTo === null) {
      captureMessage('No redirectTo location provided', Severity.Error);
      dispatch({ type: 'error', error: SOMETHING_WENT_WRONG_MESSAGE });
    } else {
      authenticate(token)
        .then(() => {
          addBreadcrumb({
            category: 'login',
            message: 'Successfully logged in',
          });
          dispatch({ type: 'success' });
          reachNavigate(urlWithUtmInfo(redirectTo), { replace: true });
          analytics.identifyUser(token);
        })
        .catch((e) => {
          addBreadcrumb({
            category: 'login',
            message: 'Failed to log in',
          });
          captureException(e);
          dispatch({ type: 'error', error: SOMETHING_WENT_WRONG_MESSAGE });
        });
    }
  }, [redirectTo, token]);

  return [state.isAuthenticating, state.error];
};

type BrandNode = LayoutBrandData;

type QueryData = {
  brand: BrandNode;
};

type AuthenticatePageProps = { data: QueryData; location: Location };

const AuthenticatePage: React.FC<AuthenticatePageProps> = ({
  data,
  location,
}) => {
  // Extract search parameters
  const q = new URLSearchParams(location.search);
  const token = q.get('token');
  const redirectTo = q.get('redirectTo');

  const [isAuthenticating, error] = useAuthenticateAndRedirect(
    token,
    redirectTo,
  );

  const statusMsg = isAuthenticating ? (
    <Spinner />
  ) : error ? (
    error
  ) : (
    <Spinner />
  );

  return (
    <Layout brand={data.brand}>
      <section className="section message">
        <div className="container--small center">
          <h1 className="heading1">{statusMsg}</h1>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query AuthenticatePageBrandInfo($brandId: String) {
    brand: brandsJson(id: { eq: $brandId }) {
      ...LayoutBrandFragment
    }
  }
`;

export default AuthenticatePage;
